/** @format */

import { LogoImg, LogoText, LogoWrapper } from './style';
import LogoAsset from 'assets/imgs/logomini.png';

const Logo = () => {
  return (
    <LogoWrapper>
      <LogoText>Cargo Prime</LogoText>
      <LogoImg src={LogoAsset} />
    </LogoWrapper>
  );
};

export default Logo;
